<template>
    <div>
        <div class="card-toolbar mb-5">
            <button v-if="$can('roles.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('roles.new_role') }}
            </button>
            
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <!-- <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">{{ $t('MENU.Roles_Management') }}
                    <span class="text-muted pt-2 font-size-sm d-block">{{ $t('roles.roles_management_section') }}</span></h3>
                </div>
                
            </div> -->
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actions" slot-scope="props">
                        <v-icon small v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" v-if="$can('roles.update')" class="mr-2 text-info" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('roles.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

        <b-modal ref="modal" hide-footer :title="$t('roles.role')">
            <div class="form-group">
                <label for="name">{{$t('roles.role_name')}}</label>
                <input v-model="item.name" type="text" class="form-control" id="name" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('roles.role_name')">
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
                </span> 
            </div>
            <div class="form-group">
                <label for="description">{{$t('roles.description')}}</label>
                <textarea v-model="item.description" type="text" class="form-control" id="description" :placeholder="$t('roles.description')"></textarea>
            </div>
            <div class="d-flex justify-content-end">
                <button type="reset" class="btn mt-2 mr-1 btn-secondary" @click="hideModal">{{ $t('close') }}</button>
                <button class="btn mt-2 mr-1 btn-primary" @click="saveRole">{{ $t('save') }}</button>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index",
        components: {},
        data() {
            return {
                item: {
                    name: '',
                    description: "",
                },
                filter: {
                    sortBy: 'id',
                },
                validation: null,
                columns: ['name', 'description', 'status', 'created_at', 'actions'],
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        name: that.$t('roles.role_name'),
                        description: that.$t('roles.description'),
                        status: that.$t('roles.status'),
                        created_at: that.$t('roles.created_at'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['role_name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query("roles", {..._params}).catch(function (error) {
                            console.error(error)
                        })

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Roles_Management")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },

            editItem(item) {
                this.$router.push({name: 'roles.edit', params: {id: item.id}});
            },
            actionDelete(item){
                console.log(item);
                    ApiService.delete("roles/" + item.id).then((response) => {
                        this.getFetch();
                        console.log(response);
                        this.$successAlert(response.data.message)
                    }).catch(({errors}) => {
                        console.error(errors);
                    })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            showModal() {
                this.$refs['modal'].show()
            },
            hideModal() {
                this.$refs['modal'].hide()
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },
            saveRole() {
                ApiService.post("roles", this.item).then(() => {
                    this.afterSaveRole();
                }).catch((error) => {
                  this.validation = error.response ? error.response.data.errors : null;
                });
            },
            afterSaveRole() {
                this.item = {name: '', description: '',};
                this.hideModal();
                this.getFetch();
            }
        },
    };
</script>
